@font-face { font-family: 'raleway'; src: url('../fonts/Raleway-Regular.ttf') format('truetype'); }
@font-face { font-family: 'source-code'; src: url('../fonts/SourceCodePro-Regular.ttf') format('truetype'); }

body {
    --bgr-color: #1D1F20;
    background-color: var(--bgr-color);

    font-family: 'raleway', serif;
    color: #fff;
}

h1 {
    text-align: center;
    color: #dbefff !important;
    font-weight: bolder;
    text-decoration: underline;
    font-size: 6.5vw;
}

.white.icon {
    --color: #fff;
}

.hover-blue:hover.icon {
    --color: #2196F3;
}

.locked.icon {
    --color: grey !important;
    cursor: default !important;
}

.clickable {
    cursor: pointer;
}

.icon {
    background-color: var(--color);
    -webkit-mask:  var(--icon-url) no-repeat 100% 100%;
    mask: var(--icon-url) no-repeat 100% 100%;
    mask-size: contain;
    -webkit-mask-size: contain;
    background-repeat: no-repeat;
    color: transparent;
    font-size: inherit;
    background-size: 100% 100%;
    font-family: 'source-code';
}
