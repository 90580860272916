@import './settings';
@import '~fork-awesome/scss/fork-awesome';

table, th, td {
    border: transparent;
    border-collapse: collapse;
}

th, td {
    padding: 10px;
}

th {
    text-align: left;
}

td {
    text-align: right;
    float: right;
}

#mapLabel {
    padding: 3px;
    border: 2px solid #fff;
    border-radius: 5px;
    cursor: pointer !important;

    &hover {
        color: #2196F3;
        border: 2px solid #2196F3;
    }
}

#menus {
    position: absolute;
    margin: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.map-upload {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: baseline;
}

.menu-style .select-btn {
    background: none !important;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit;
    cursor: pointer;
    width: auto;

    &:hover {
        color: #2195F3;
    }
}

#mapName {
    margin-left: 3%;
    font-family: "source-code", serif;
    font-size: 1.3vw;
    text-align: center;
}

.table-spacer {
    height: 20px !important;
}

.special-right-align {
    float: none;
    display: flex;
    flex-direction: row-reverse;
}

.right-align {
    text-align: right;
}

.center-align {
    text-align: center;
}

.swipe-wrap {
    width: 70%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    .text-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 100%;

        > * {
            position: absolute;
            text-align: center;
        }
    }
}

.menu-style {
    animation-fill-mode: forwards;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    > .btn-wrap {
        margin: 2% auto auto;
        width: 40%;
        max-width: 600px;
        height: 45%;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    > .settings-wrap {
        display: table;
        margin: 0 auto;
        width: 45%;
        height: 80%;
        font-size: 1.5vw;
    }

    > .game-header {
        top: 130%;
    }
}

.fancy-bgr {
    transform: translateY(-20px);
    width: 100%;
    height: 130%;
    position: relative;
    overflow: hidden;
}

.fancy-bgr:before { /*from: https://www.sitepoint.com/css3-transform-background-image/ */
    opacity: 0.5;
    content: "";
    position: absolute;
    width: 200%;
    height: 200%;
    top: -50%;
    left: -50%;
    z-index: -1;
    background: url("../img/eule_blau.png") 0 0 repeat;
    -webkit-transform: rotate(30deg);
    transform: rotate(30deg);
}
    

#menus .bgr-offset::after {
    overflow: scroll;
    background-position: 50% 100%;
}

.x {
    position: absolute;
    background: transparent;
    color: white;
    top: -1px;
    right: -1px;
    font-size: 3.5vw;
    padding: 0;
    border: none;
    background: none;
    margin: 2%;
    cursor: pointer !important;

    &:hover {
        color: #2196F3;
    }
}

#menus .btn { 
    margin-bottom: 15px;
    background: linear-gradient(to top right, #67bfff, #2196F3);
    border-width: 10px;
    border-radius: 30px;
    border-color: white;
    height: 25%;
    font-size: 94%;
    border-color: #fff;
    color: #fff;
    text-align: center;
    cursor: pointer !important;
    display: block;

    &:hover {
        background: #2195F3;
        transform: scale(1.1);
    }

    > p {
        position: relative;
        top: 50%;
        transform: translateY(-145%);
        font-size: 2vw;
    }
}

#menus .btn:hover::after, #menus .btn:focus::after {
    animation-play-state: running;
}

.hide {
    z-index: -99999999;
    text-indent: -99999999999;
    background: red !important;
    visibility: hidden;
    height: 0px;

    .show {
        visibility: hidden !important;
    }
}

.hide .slider, .hide .slider:before {
    -webkit-transition: none;
    transition: none;
}

.show { /*from: https://stackoverflow.com/questions/11679567/using-css-for-fade-in-effect-on-page-load */
    visibility: visible;
    --dur: 1.5s;
    animation: fadein var(--dur);
}

@keyframes animatedgradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.nav-buttons {
    display: flex;
    justify-content: center;

    button {
        margin-left: 5px;
        margin-right: 5px;
    }
}

#credit-space {
    background: rgba(0, 0, 0, 0.5);
    text-align: center;
    padding: 10px;
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    display: inline-block;

    p {
        margin: 0;
        color: #dbefff;
    }

    h1, h2 {
        display: inline-block;
    }

    h1 {
        opacity: 1;
    }

    h2 {
        color: #dcd12a;
        font-size: 1.5rem;
    }
}

.owl-banner {
    background-image: url("../img/eule_blau.png");
    background-repeat: repeat-y;
    height: 100%;
    width: 100px;
    background-size: contain;
}

.stary-sky {
    background-image: url("../img/stary-sky.svg");
    background-size: 20%;
}

.vacation {
    background-image: url("../img/vacation.svg");
    background-size: cover;
}
